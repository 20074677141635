import React from "react";
import VideoAutoPlay from "./layouts/videoAutoPlay";
import { Link } from "gatsby";
import PortableText from "./portableText";
import { navigate } from "gatsby";

const VideoPage = ({ video }) => {
  return (
    <div className="relative antialised">
      <div className="h-screen my-auto flex items-center justify-center">
        <div className="w-full lg:w-[1200px]">
          <VideoAutoPlay
            videoSrcURL={video.url}
            videoTitle={video.title}
            className="w-full h-full"
            key={video.id}
          />
        </div>
      </div>
      {/* This text below */}
      <div className="videoPageDesc" key={video._rawBodyPortableText._key}>
        <PortableText blocks={video._rawBodyPortableText} />
      </div>

      <div className="flex flex-row w-full px-4  lg:px-[80px] justify-between sticky mb-[80px] bottom-4">
        <Link
          className="text-black font-caslon hover:font-casloni"
          to={`/${video.postReference.post.slug.current}`}
        >
          See full project
        </Link>
        <div
          className="text-black font-caslon cursor-pointer hover:font-casloni"
          onClick={() => navigate(-1)}
        >
          Close
        </div>
      </div>
    </div>
  );
};

export default VideoPage;
