import React, { useState } from "react";
import { graphql } from "gatsby";
import Seo from "../../components/seo";
import Container from "../../components/layouts/container";
import LayoutVideo from "../../components/layouts/layoutVideo";
import VideoPage from "../../components/videoPage";
import LayoutVideoDoc from "../../components/layouts/layoutVideoDoc";

const MovingImageIndex = ({ data }) => {
  const { site, sanityVideoDoc } = data;

  const video = sanityVideoDoc;

  return (
    <main key={video.id}>
      <LayoutVideoDoc>
        <Seo
          title={site.title}
          description={site.description}
          keywords={site.keywords}
        />
        <Container>
          <VideoPage video={video} />
          <h1 className="postTitle">{video.title}</h1>
        </Container>
      </LayoutVideoDoc>
    </main>
  );
};

export const query = graphql`
  query ($id: String) {
    sanityVideoDoc(id: { eq: $id }) {
      slug {
        current
      }
      postReference {
        _key
        post {
          title
          slug {
            current
            _key
          }
          id
        }
      }
      url
      title
      _rawBodyPortableText
      id
    }
    site: sanitySiteSettings {
      title
      keywords
      description
      id
    }
  }
`;
export default MovingImageIndex;
