import React from "react";
import { useStaticQuery, graphql } from "gatsby"
import { Link } from "gatsby";
import MailChimpForm from "../MailchimpForm";
import FooterLogo from "./FooterLogo";

const FooterSans = ({siteTitle }) => {
    const FooterData = useStaticQuery(graphql`
    {
      navigation: sanitySiteSettings {
        bottomNavigation {
          ... on SanityLink {
            _key
            where
            href
            _type
          }
          ... on SanityPage {
            id
            name
            slug {
              current
            }
            _key
            _type
            _id
          }
          ... on SanityPost {
            id
            title
            slug {
              current
            }
            _key
            _type
            _id
          }
        }
      }
      home:sanityHome {
        intro
      }
    }
    
  `)

  const FooterNav = FooterData.navigation.bottomNavigation.map((nav) => {
    let el = null;
    switch (nav._type) {
      case "page":
        el = <Link className="elFoot" key={nav.id} to={`/${nav.slug.current}`}>{nav.name}</Link> 
        break;
      case "post":
        el = <Link className="elFoot" key={nav.id} to={`/${nav.slug.current}`}>{nav.name}</Link> 
        break;
      case "link":
        el = <a className="elFoot" key={nav._key} href={`${nav.href}`} target="_blank" rel="noindex nofollow">{nav.where}</a> 
        break;
        default:
      el = null;
    }
    return el;
});

const intro = FooterData.home.intro;
return ( 
<>
<footer>
  <FooterLogo name={siteTitle} />
           <div className="footerEl">{FooterNav} &copy; {new Date().getFullYear()}
           {/* {siteTitle} &copy; {new Date().getFullYear()} */}<h1 className="pt-8">{intro}</h1></div>
    </footer>
</>
     );
}
 
export default FooterSans;